import * as React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_recruit.module.scss"

import ProcessWeb from "src/images/recruit-process-web.jpg"
import ProcessMobile from "src/images/recruit-process-mobile.jpg"

const Position5Page = () => (
  <Layout>
    <Seo title="フロントエンドエンジニア募集要項" />
    <div className="pageWrapper">
      <div className="pageTtl">
        <h1>採用情報</h1>
      </div>

      <h2 className="h2Blue">フロントエンドエンジニア募集要項</h2>

      <h3 className={styles.detailTtl}>概要</h3>
      <div className={styles.detailTxt}>
        <p>
          新規・既存サービスのフロントエンド開発を、バックエンド開発者やデザイナーと協力して行って頂きます。
          本人希望とスキルに合わせて、要件定義・設計といった上流工程やバックエンド開発など、様々な工程を担当することも可能です。
        </p>
      </div>

      <h3 className={styles.detailTtl}>仕事内容</h3>
      <div className={styles.detailTxt}>
        <ul>
          <li>新規サービス・既存サービスの新機能の画面設計・開発など</li>
        </ul>
      </div>

      <h3 className={styles.detailTtl}>開発環境</h3>
      <div className={styles.detailTxt}>
        <p>自分の好きな環境で開発を行えます。
          AWSなどの先端クラウド技術を積極的に取り入れており、自由に実験できるようにエンジニア全員にアカウントを提供しています。
        </p>
        <ul>
          <li>開発言語：JavaScript、CSS、HTML、PHP、C#</li>
          <li>フレームワーク：Next.js（React）、Vue、Laravel、Blazor</li>
          <li>OS：Linux／Mac／Windows</li>
          <li>DB：MySQL／PostgreSQL</li>
          <li>インフラ：AWS、GCP</li>
        </ul>
      </div>

      <h3 className={styles.detailTtl}>魅力</h3>
      <div className={styles.detailTxt}>
        <p>自社サービスの開発なので、数字という結果がダイレクトに伝わります。
          開発して終わりではなく、リリース後のサービスの成長を感じられる環境です。
        </p>
        <ul>
          <li>自社・グループサービスのシステムを開発することにつきます。自身で行った開発がダイレクトにユーザーに届き、結果として返ってきます。</li>
          <li>昨今謳われている企業のDXに触れることができます。</li>
        </ul>
      </div>

      <h3 className={styles.detailTtl}>応募資格</h3>
      <div className={styles.detailTxt}>
        <p>【必須要件】</p>
        <ul>
          <li>JavaScript + CSS + HTMLを使用したリッチインターフェースの実務1年以上の開発経験</li>
        </ul>
        <p>【歓迎要件】</p>
        <ul>
          <li>ReactやVueなどコンポーネント指向のライブラリ・フレームワークを利用した開発経験</li>
          <li>フロントエンド開発環境の構築経験</li>
          <li>データベースの知識やバックエンド開発の経験</li>
        </ul>
        <p>【求める人物像】</p>
        <ul>
          <li>主体的に仕事に取り組める方</li>
          <li>最新テクノロジーを使ってユーザー業務に生かしたい方</li>
          <li>チーム開発が好きな方</li>
        </ul>
      </div>

      <h3 className={styles.detailTtl}>給与</h3>
      <div className={styles.detailTxt}>
        <p>300万円〜700万円<br /><span>※ ご経験・スキルを考慮して決定いたします。</span></p>
        <ul>
          <li>給与改定：年1回</li>
          <li>賞与：年2回</li>
          <li>試用期間：3ヶ月（条件等は変わりません）</li>
          <p>※ アルバイト契約・副業契約もOK</p>
        </ul>
      </div>

      <h3 className={styles.detailTtl}>採用人数</h3>
      <div className={styles.detailTxt}>
        <p>複数名</p>
      </div>

      <h3 className={styles.detailTtl}>勤務時間</h3>
      <div className={styles.detailTxt}>
        <p>9:00 ~ 18:00（所定労働時間：8時間0分）</p>
        <ul>
          <li>休憩時間：60分</li>
          <li>時間外労働：有り（月10~20時間）</li>
          <p>※フレックス制度あり</p>
        </ul>
      </div>

      <h3 className={styles.detailTtl}>勤務地</h3>
      <div className={styles.detailTxt}>
        <ul>
          <li>
            ハードオフ未来ラボ<br />
            <p>新潟県新潟市中央区笹口1丁目2番 PLAKA2ビル 2F</p>
          </li>
          <li>
            長岡CSセンター<br />
            <p>新潟県長岡市東蔵王2丁目6番20号吉沢ビル2F</p>
          </li>
          <li>
            十日町開発センター<br />
            <p>新潟県十日町市丸山町6番地11</p>
            </li>
        </ul>
      </div>

      <h3 className={styles.detailTtl}>休日・休暇</h3>
      <div className={styles.detailTxt}>
        <ul>
          <li>週休2日制度</li>
          <li>年123日（118日+5日有給休暇取得推奨）※2023年度実績</li>
          <li>慶弔休暇・産前産後休暇・育児介護休業・特別休暇</li>
        </ul>
      </div>

      <h3 className={styles.detailTtl}>待遇・福利厚生</h3>
      <div className={styles.detailTxt}>
        <ul>
          <li>各種手当<br />職務手当 / 出張手当 / 残業手当 / 通勤手当等支給 / 単身赴任手当 / 住宅手当（※支給条件有）/ 慶弔見舞金制度 / 資格取得奨励制度 / 書籍購入補助制度</li>
          <li>福利厚生<br />借上げ社宅 / 引越費用全額会社負担 / 社会保険完備 / 社員持株会 / 退職金共済 / 定期健康診断（年1回）/ 慶弔見舞金 / 親会社店舗にて社員割引・社員買取アップ / <br />
            結婚祝金 / 出産祝金 / 育児休業復帰祝金（育児休業給付金の差額の20%相当）/ 育児介護短時間制度（子の中学卒業年度末まで、家族の介護のため）
          </li>
          <li>定年<br />65歳（再雇用70歳まで）</li>
        </ul>
      </div>

      <h2 className="h2Blue">採用の流れ</h2>
      <p className="productMsg">
        選考プロセスの基本的な流れについてご説明します。
      </p>
      <img src={ ProcessWeb } alt="採用の流れ" className={styles.processWeb}/>
      <img src={ ProcessMobile } alt="採用の流れ" className={styles.processMobile}/>
      <p className="italic">※上記は変更となる可能性もございます。</p>

      <h2 className="h2Blue">応募方法</h2>
      <p className="productMsg">
        企業説明をご希望の方、エントリーをご希望の方は応募フォームよりエントリーをお願いします。
      </p>

      <div className="btnLarge">
        <a href="/recruit/mid-career/job-application/">中途採用応募フォームはこちら
          <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
        </a>
      </div>
    </div>
  </Layout>
)

export default Position5Page
